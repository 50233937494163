@import '../../_base.scss';
$dashboard-views-bar-height: 44px;

.dashboard {
  display: flex;
  height: 100vh;

  &__topbar {
    width: 100%;
    min-height: 60px;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    border-bottom: 1px solid #c7d5db;

    &-right,
    &-left {
      display: flex;
    }

    &-actions {}

    &-sidebar-toggle {
      display: flex;
      align-items: center;
      margin-left: 2px;

      .#{$ns}-button {
        color: #6b8193;

        &,
        &:hover,
        &:focus {
          border-radius: 0;
          box-shadow: none;
          background-color: transparent;
          border: 0;
        }
      }
    }

    &-navbar {
      display: flex;
      align-items: center;
      margin-right: 4px;

      .#{$ns}-button {
        color: #1552c8;
        font-weight: 5000;
        font-size: 14px;

        &:hover,
        &:focus {
          background-color: rgba(178, 220, 253, 0.15);
        }

        .#{$ns}-icon {
          color: #1552c8;
        }
      }

      .bp4-navbar-divider {
        margin: 0 8px;
      }

      .form-group-quick-new-downDrop {
        .bp4-popover-target .bp4-button {
          color: #1552c8;
        }

        .bp4-button::after {
          content: none;
        }
      }
    }

    &-user {
      display: flex;
      align-items: center;
      margin-right: 14px;

      .bp4-button:not([class*='bp4-intent-']):not(.bp4-minimal) {
        padding: 0;
        background-size: contain;
        border-radius: 50%;
        height: 32px;
        width: 32px;

        .user-text {
          font-size: 12px;
          color: #fff;
        }

        &,
        &:hover,
        &:focus {
          background-color: #cb20e5;
          border: 0;
          box-shadow: none;
        }
      }
    }

    &-subscription-msg {
      display: flex;
      flex-direction: row;
      padding-right: 6px;
      font-size: 12px;

      span {
        margin: auto;
      }
    }
  }

  &__breadcrumbs {
    display: flex;
    margin-left: 16px;
    align-items: center;

    // Breadcrumbs component.
    .#{$ns}-breadcrumbs-collapsed {
      background: $light-gray3;

      &:hover {
        background: $light-gray2;
      }

      &::before {
        background: escape-svg($breadcrumbs-collapsed-icon) center center;
        width: 15px;
        height: 15px;
      }
    }
  }

  &__back-link {
    margin-left: 20px;
    display: flex;

    a {
      margin: auto 0;
      /*!rtl:ignore*/
      direction: ltr;
    }
  }

  &__organization-name {
    font-size: 14px;
    font-weight: 400;
    color: #818ca9;
    margin: 0 0 0 15px;
    display: flex;
    align-items: center;
  }

  &__actions-bar {
    border-bottom: 2px solid #e1e2e8;

    .bp4-navbar-divider {
      border-left-color: rgb(199, 214, 219);
    }

    .#{$ns}-navbar {
      box-shadow: none;
      padding-left: 12px;
      padding-right: 12px;

      &,
      &-group {
        height: 40px;
      }

      .#{$ns}-navbar-divider {
        margin-left: 0;
        margin-right: 0;
      }

      .#{$ns}-button {
        color: #32304a;
        padding: 8px 12px;

        &:hover {
          background: rgba(167, 182, 194, 0.12);
          color: #32304a;
        }

        &.bp4-minimal:active,
        &.bp4-minimal.bp4-active {
          background: #a7b6c21f;
          color: #32304a;
        }

        &.has-active-filters {

          &,
          &.bp4-active,
          &:active {
            background: #eafbe4;
          }
        }

        .#{$ns}-icon {
          color: #32304a;
        }

        &.#{$ns}-minimal.#{$ns}-intent-danger {
          color: #c23030;

          &:hover,
          &:focus {
            background: rgba(219, 55, 55, 0.1);
          }
        }

        &.button--blue-highlight {
          background-color: #ebfaff;

          &:hover,
          &:focus {
            background-color: darken(#ebfaff, 2.5%);
          }
        }

        &.button--gray-highlight {
          background-color: rgba(127, 189, 255, 0.1);
        }
      }

      .button--table-views {
        .#{$ns}-icon {
          color: #1183da;
        }

        .#{$ns}-button-text {
          margin-right: 2px;
        }

        .#{$ns}-icon-caret-down {
          margin-right: 0;
        }
      }

      .bp4-control.bp4-switch {
        margin-bottom: 0;
        margin-left: 8px;
        color: #3d3b53;

        .bp4-control-indicator {
          height: 16px;
          min-width: 1.8em;
        }

        .bp4-control-indicator::before {
          box-shadow: 0 0 0;
        }

        input~.bp4-control-indicator {
          background: rgba(167, 182, 194, 0.55);
        }

        input:checked~.bp4-control-indicator {
          background: #0069ff;
        }
      }
    }
  }

  &__breadcrumbs {
    display: flex;
    align-items: center;
    margin-left: 1rem;
  }

  &__title {
    align-items: center;
    display: flex;
    margin-left: 2px;

    h1 {
      font-size: 22px;
      color: #48485c;
      font-weight: 500;
      margin: 0;
    }

    h3 {}

    .button--view-edit {
      svg {
        color: #929699;
      }

      &:hover,
      &:focus {
        svg {
          color: #5c7080;
        }
      }
    }
  }

  &__hint {
    display: inline-block;
    margin-top: 4px;
    margin-left: 4px;
  }

  &__subtitle {}

  &__insider {}

  &__offline-badge {
    display: flex;
    align-items: center;
    padding: 0px 6px;
    height: 24px;
    border-radius: 4px;
    margin-right: 18px;
    white-space: nowrap;
    font-size: 14px;
    color: rgba(22, 12, 12, 0.6);
    border: 1px solid rgba(0, 0, 0, 0.16);
    margin: auto;
    margin-left: 12px;
  }

  &-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 850px;

    .sidebar--mini-sidebar+& {
      margin-left: 50px;
      width: calc(100% - 50px);
    }
  }

  &__insider {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    background-color: #fbfbfb;

    >.dashboard__loading-indicator {
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  &__page {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
  }

  &__fallback-loading {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #fbfbfb;

    .bp4-spinner {
      margin: auto;
    }
  }

  &__page-content {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;

    .bigcapital-datatable {
      .table {

        .thead,
        .tbody {

          .th.selection,
          .td.selection {
            padding-left: 16px;
          }
        }
      }
    }

    .dashboard__loading-indicator {
      padding-top: 150px;
    }
  }

  &__centered-empty-status {
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    margin: auto;

    .datatable-empty-status {
      margin: auto;
      padding-bottom: 40px;
    }
  }

  &__datatable {
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    background: #fff;
    margin: 22px 32px;
    border: 1px solid #d2dce2;

    .bigcapital-datatable {
      display: flex;
      flex-direction: column;
      flex: 1 0 0;

      .pagination {
        margin-top: auto;
      }

      &:not(.has-pagination) {
        padding-bottom: 30px;
      }
    }

    .datatable-empty-status {
      margin-top: auto;
      margin-bottom: auto;
      padding-bottom: 20px;
    }
  }

  &__preferences-topbar {}

  &__footer {
    margin-top: auto;
    padding: 8px 0;
    border-top: 1px solid #d2dce2;

    .footer-links {
      text-align: center;

      >div {
        font-size: 12px;
        margin-right: 20px;
        display: inline;

        a {
          color: #8c8c8c;

          &:hover {
            color: $blue2;
          }
        }
      }
    }
  }

  &__card {
    border: 1px solid #d2dce2;
    background: #fff;

    &--page {
      flex: 1 0 0;
      margin: 20px;
    }
  }

  &__error-boundary {
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;

    h1 {
      font-size: 26px;
      font-weight: 600;
      margin: 0px 0 10px;
      color: #2c3a5d;
    }

    p {
      font-size: 16px;
      color: #1f3255;
      opacity: 0.8;
    }

    .bp4-icon {
      margin-top: 6px;

      path {
        fill: #a1afca;
      }
    }
  }
}

.tabs--dashboard-views {
  user-select: none;

  .#{$ns}-tab {
    color: #646a7d;
    font-size: 14px;
    line-height: $dashboard-views-bar-height - 2px;
    font-weight: 400;
    padding: 0;
    margin-right: 0;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 2px;

    &[aria-selected='true'] {
      color: #0052cc;
      font-weight: 600;
    }
  }

  .#{$ns}-tab-indicator-wrapper {
    .#{$ns}-tab-indicator {
      height: 2px;
    }
  }

  .button--new-view {
    margin: 0;
    height: $dashboard-views-bar-height;
    min-width: 35px;
    padding-left: 5px;
    padding-right: 5px;

    &,
    &:hover,
    &:active,
    &:focus {
      background: transparent;
      border: 0;
      box-shadow: 0 0 0;
    }

    .#{$ns}-icon {
      color: #a7a7a7;
    }
  }
}

.navbar--dashboard-views {
  box-shadow: 0 0 0;
  border-bottom: 1px solid #d2dce2;
  height: $dashboard-views-bar-height;
  padding: 0 20px;

  .bp4-navbar-group {
    height: $dashboard-views-bar-height;
  }

  .bp4-tab[aria-selected='true'] {
    box-shadow: inset 0 -2px 0 #0052cc;
  }

  .bp4-button {
    &:hover {
      background: rgba(167, 182, 194, 0.12);
      color: #32304a;
    }
  }
}

.navbar--omnibar {
  -webkit-filter: blur(0);
  filter: blur(0);
  opacity: 1;
  top: 20vh;
  left: calc(50% - 250px);
  z-index: 21;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 4px 8px rgba(16, 22, 26, 0.2),
    0 18px 46px 6px rgba(16, 22, 26, 0.2);
  background-color: #fff;
  width: 600px;

  .bp4-input {
    padding-left: 40px !important;
  }
}

.menu--logged-user-dropdown {
  .menu-item--profile {
    .org {
      font-size: 12px;
      color: #777;
    }
  }
}
